:local {
    .card {
        cursor: pointer;
        transition: all 0.25s ease-in-out !important;
        outline: none !important;
        background-color: transparent !important;
        border: none !important;
        height: 650px;
        box-shadow: none !important;

        img {
            max-width: auto !important;
            max-height: 650px;
            outline: none !important;
            border: none !important;
            box-shadow: none !important;

            &:focus,
            &:hover {
                transform: scale(1.05);
            }

            &:active {
                transform: scale(1.02) rotate(2.5deg);
                outline: none !important;
                background-color: transparent !important;
                border: none !important;
                box-shadow: none !important;
            }
        }
    }
}
